<template>
  <fullscreen v-model="isFullScreen" class="background">
    <div class="header-bar">
      <div style="display: flex; align-items: center; color: #fffe; margin: 0 24px">
        <div style="flex: 1"></div>
        <div style="flex: 1; font-size: 30px; font-weight: bold; text-align: center">生产数据可视化大屏</div>
        <div style="flex: 1; font-size: 16px; text-align: right">
          <div style="display: flex; justify-content: flex-end">
            <div>{{ currentTime }}</div>
            <div style="cursor: pointer; margin: 0 12px" @click="toggleFullScreen">
              <a-icon v-if="isFullScreen" type="fullscreen-exit" style="font-size: 24px" />
              <a-icon v-else type="fullscreen" style="font-size: 24px" />
            </div>
            <div style="cursor: pointer" @click="$router.go(-1)">
              <a-icon type="arrow-left" style="font-size: 24px" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; gap: 8px; height: calc(100vh - 80px); padding: 0 8px 8px 8px">
      <div style="height: 220px; display: flex; flex-direction: row; gap: 8px">
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #a0d911">实时分拣统计</div>
          <div class="card-content" style="display: flex; align-items: center; text-align: center">
            <div style="flex: 1">
              <div style="font-weight: bold">
                <span style="font-size: 32px">100</span>
                <span style="font-size: 14px; margin-left: 4px">件</span>
              </div>
              <div style="font-size: 14px">分拣数量</div>
            </div>
            <div style="flex: 1">
              <div style="font-weight: bold">
                <span style="font-size: 32px">100</span>
                <span style="font-size: 14px; margin-left: 4px">千克</span>
              </div>
              <div style="font-size: 14px">分拣重量</div>
            </div>
            <div style="flex: 1">
              <div style="font-weight: bold">
                <span style="font-size: 32px">100</span>
                <span style="font-size: 14px; margin-left: 4px">立方</span>
              </div>
              <div style="font-size: 14px">分拣体积</div>
            </div>
          </div>
        </div>
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #a0d911">实时分拣状态</div>
          <div class="card-content" style="display: flex; align-items: center; text-align: center">
            <div style="flex: 1">
              <div style="font-size: 32px; font-weight: bold">3</div>
              <div style="font-size: 14px">分拣异常数</div>
            </div>
            <div style="flex: 1">
              <div style="font-size: 32px; font-weight: bold">100</div>
              <div style="font-size: 14px">分拣总次数</div>
            </div>
            <div style="flex: 1">
              <div style="font-size: 32px; font-weight: bold">97%</div>
              <div style="font-size: 14px">分拣成功率</div>
            </div>
          </div>
        </div>
        <div class="card" style="flex: 1; padding-bottom: 0">
          <div class="card-title" style="border-left: 4px solid #a0d911">实时道口统计</div>
          <div class="card-content" style="display: flex; align-items: center; text-align: center">
            <div id="container1" style="height: calc(100% - 8px); width: calc(100%)"></div>
          </div>
        </div>
      </div>

      <div style="flex: 1; display: flex; flex-direction: row; gap: 8px">
        <div class="card" style="flex: 1; padding-bottom: 0">
          <div class="card-title" style="border-left: 4px solid #1890ff">今日分拣状态</div>
          <div class="card-content">
            <div id="container2" style="height: calc(100% - 8px)"></div>
          </div>
        </div>
        <!-- <div class="card" style="flex: 1; padding-bottom: 0">
          <div class="card-title" style="border-left: 4px solid #1890ff">本月分拣状态</div>
          <div class="card-content">
            <div id="container3" style="height: calc(100% - 8px)"></div>
          </div>
        </div> -->
        <div class="card" style="flex: 1; padding-bottom: 0">
          <div class="card-title" style="border-left: 4px solid #1890ff">今年分拣状态</div>
          <div class="card-content">
            <div id="container4" style="height: calc(100% - 8px)"></div>
          </div>
        </div>
      </div>
      <div style="flex: 1; display: flex; flex-direction: row; gap: 8px">
        <div class="card" style="flex: 1; padding-bottom: 0">
          <div class="card-title" style="border-left: 4px solid #ffa940">今日分拣趋势</div>
          <div class="card-content">
            <div id="container5" style="height: calc(100% - 8px)"></div>
          </div>
        </div>
        <!-- <div class="card" style="flex: 1; padding-bottom: 0">
          <div class="card-title" style="border-left: 4px solid #ffa940">本月分拣趋势</div>
          <div class="card-content">
            <div id="container6" style="height: calc(100% - 8px)"></div>
          </div>
        </div> -->
        <div class="card" style="flex: 1; padding-bottom: 0">
          <div class="card-title" style="border-left: 4px solid #ffa940">今年分拣趋势</div>
          <div class="card-content">
            <div id="container7" style="height: calc(100% - 8px)"></div>
          </div>
        </div>
      </div>
    </div>
  </fullscreen>
</template>

<script>
import { Chart } from "@antv/g2";
import moment from "moment";

export default {
  components: {
    // StockAgePanel: () => import("./StockAgePanel"),
    // ReceiptPanel: () => import("./ReceiptPanel"),
    // ShelvePanel: () => import("./ShelvePanel"),
    // PickingPanel: () => import("./PickingPanel"),
    // DeliveryPanel: () => import("./DeliveryPanel"),
  },
  data() {
    return {
      currentTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      timeUpdateInterval: null,
      dataUpdateInterval: null,
      isFullScreen: false,
      isReloadPage: false,
    };
  },
  computed: {},
  methods: {
    queryData() {},
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.isReloadPage = true;
      setTimeout(() => (this.isReloadPage = false), 100);
    },
    renderContainer1() {
      const data = [
        { year: "A01", sales: 238 },
        { year: "A02", sales: 152 },
        { year: "A03", sales: 141 },
        { year: "A04", sales: 125 },
        { year: "A05", sales: 88 },
        { year: "A06", sales: 78 },
        { year: "A07", sales: 58 },
        { year: "A08", sales: 38 },
      ];

      const chart = new Chart({
        container: "container1",
        autoFit: true,
        theme: "classicDark",
      });

      chart
        .interval()
        .coordinate({ transform: [{ type: "transpose" }] })
        .data(data)
        .encode("x", "year")
        .encode("y", "sales")
        .axis("x", { title: false })
        .axis("y", { title: false });

      chart.render();
    },
    renderContainer2() {
      const data = [
        { item: "正常", count: 950, percent: 0.7 },
        { item: "无条码", count: 20, percent: 0.15 },
        { item: "多条码", count: 10, percent: 0.1 },
        { item: "零小车码", count: 10, percent: 0.1 },
        { item: "其他异常", count: 10, percent: 0.05 },
      ];

      const chart = new Chart({
        container: "container2",
        autoFit: true,
        theme: "classicDark",
      });

      chart.coordinate({ type: "theta", outerRadius: 0.8 });

      chart
        .interval()
        .data(data)
        .transform({ type: "stackY" })
        .encode("y", "percent")
        .encode("color", "item")
        .legend("color", { position: "bottom", layout: { justifyContent: "center" } })
        .label({
          position: "outside",
          text: (data) => `${data.item}: ${data.percent * 100}%`,
        })
        .tooltip((data) => ({
          name: data.item,
          value: `${data.percent * 100}%`,
        }));

      chart.render();
    },
    renderContainer3() {
      const data = [
        { item: "事例一", count: 40, percent: 0.4 },
        { item: "事例二", count: 21, percent: 0.21 },
        { item: "事例三", count: 17, percent: 0.17 },
        { item: "事例四", count: 13, percent: 0.13 },
        { item: "事例五", count: 9, percent: 0.09 },
      ];

      const chart = new Chart({
        container: "container3",
        autoFit: true,
        theme: "classicDark",
      });

      chart.coordinate({ type: "theta", outerRadius: 0.8 });

      chart
        .interval()
        .data(data)
        .transform({ type: "stackY" })
        .encode("y", "percent")
        .encode("color", "item")
        .legend("color", { position: "bottom", layout: { justifyContent: "center" } })
        .label({
          position: "outside",
          text: (data) => `${data.item}: ${data.percent * 100}%`,
        })
        .tooltip((data) => ({
          name: data.item,
          value: `${data.percent * 100}%`,
        }));

      chart.render();
    },
    renderContainer4() {
      const data = [
        { item: "正常", count: 950, percent: 0.75 },
        { item: "无条码", count: 20, percent: 0.06 },
        { item: "多条码", count: 10, percent: 0.05 },
        { item: "零小车码", count: 10, percent: 0.08 },
        { item: "其他异常", count: 10, percent: 0.06 },
      ];

      const chart = new Chart({
        container: "container4",
        autoFit: true,
        theme: "classicDark",
      });

      chart.coordinate({ type: "theta", outerRadius: 0.8 });

      chart
        .interval()
        .data(data)
        .transform({ type: "stackY" })
        .encode("y", "percent")
        .encode("color", "item")
        .legend("color", { position: "bottom", layout: { justifyContent: "center" } })
        .scale("color", { palette: "accent" })
        .label({
          position: "outside",
          text: (data) => `${data.item}: ${data.percent * 100}%`,
        })
        .tooltip((data) => ({
          name: data.item,
          value: `${data.percent * 100}%`,
        }));

      chart.render();
    },
    renderContainer5() {
      const data = [
        { year: "00", value: 3000 },
        { year: "01", value: 3200 },
        { year: "02", value: 3400 },
        { year: "03", value: 3800 },
        { year: "04", value: 4000 },
        { year: "05", value: 4400 },
        { year: "06", value: 4800 },
        { year: "07", value: 5000 },
        { year: "08", value: 5500 },
        { year: "09", value: 6000 },
        { year: "10", value: 5500 },
        { year: "11", value: 5500 },
        { year: "12", value: 5900 },
        { year: "13", value: 5600 },
        { year: "14", value: 5300 },
        { year: "15", value: 5000 },
        { year: "16", value: 4800 },
        { year: "17", value: 4600 },
        { year: "18", value: 4400 },
        { year: "19", value: 4200 },
        { year: "20", value: 4000 },
        { year: "21", value: 3800 },
        { year: "22", value: 3400 },
        { year: "23", value: 3200 },
      ];

      const chart = new Chart({
        container: "container5",
        autoFit: true,
        theme: "classicDark",
      });

      chart
        .data(data)
        .encode("x", "year")
        .encode("y", "value")
        .axis("x", { title: false })
        .axis("y", { title: false })
        .scale("x", {
          range: [0, 1],
        })
        .scale("y", {
          domainMin: 0,
          nice: true,
        });
      chart.line().label({ text: "value", style: { dx: -10, dy: -12 } });
      chart.point().style("fill", "white").tooltip(false);

      chart.render();
    },
    renderContainer6() {
      const data = [
        { year: "2023-12", value: 3000 },
        { year: "2024-01", value: 3200 },
        { year: "2024-02", value: 3400 },
        { year: "2024-03", value: 3800 },
        { year: "2024-04", value: 4000 },
        { year: "2024-05", value: 4400 },
        { year: "2024-06", value: 4800 },
        { year: "2024-07", value: 5000 },
        { year: "2024-08", value: 5500 },
        { year: "2024-09", value: 6000 },
        { year: "2024-10", value: 5500 },
        { year: "2024-11", value: 5500 },
      ];

      const chart = new Chart({
        container: "container6",
        autoFit: true,
        theme: "classicDark",
      });

      chart
        .data(data)
        .encode("x", "year")
        .encode("y", "value")
        .axis("x", { title: false })
        .axis("y", { title: false })
        .scale("x", {
          range: [0, 1],
        })
        .scale("y", {
          domainMin: 0,
          nice: true,
        });
      chart.line().label({ text: "value", style: { dx: -10, dy: -12 } });

      chart.point().style("fill", "white").tooltip(false);

      chart.render();
    },
    renderContainer7() {
      const data = [
        { year: "2023-12", value: 30000 },
        { year: "2024-01", value: 32000 },
        { year: "2024-02", value: 34000 },
        { year: "2024-03", value: 38000 },
        { year: "2024-04", value: 40000 },
        { year: "2024-05", value: 44000 },
        { year: "2024-06", value: 48000 },
        { year: "2024-07", value: 50000 },
        { year: "2024-08", value: 55000 },
        { year: "2024-09", value: 60000 },
        { year: "2024-10", value: 55000 },
        { year: "2024-11", value: 55000 },
      ];

      const chart = new Chart({
        container: "container7",
        autoFit: true,
        theme: "classicDark",
      });

      chart
        .data(data)
        .encode("x", "year")
        .encode("y", "value")
        .axis("x", { title: false })
        .axis("y", { title: false })
        .scale("x", {
          range: [0, 1],
        })
        .scale("y", {
          domainMin: 0,
          nice: true,
        });

      chart.line().label({ text: "value", style: { dx: -10, dy: -12 } });
      chart.point().style("fill", "white").tooltip(false);

      chart.render();
    },
  },
  mounted() {
    this.queryData();
    this.timeUpdateInterval = setInterval(() => (this.currentTime = moment().format("YYYY-MM-DD HH:mm:ss")), 500);
    this.dataUpdateInterval = setInterval(() => this.queryData(), 3000);

    this.renderContainer1();
    this.renderContainer2();
    // this.renderContainer3();
    this.renderContainer4();
    this.renderContainer5();
    // this.renderContainer6();
    this.renderContainer7();
  },
  beforeDestroy() {
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
      this.timeUpdateInterval = null;
    }

    if (this.dataUpdateInterval) {
      clearInterval(this.dataUpdateInterval);
      this.dataUpdateInterval = null;
    }
  },
};
</script>

<style scoped>
.background {
  background-image: url("./background.jpg");
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.header-bar {
  height: 80px;
  background-image: url("./headerBar.png");
  background-size: 100% 100%;
  padding-top: 8px;
}

.card {
  height: 100%;
  padding: 8px 16px;
  background: rgba(6, 48, 109, 0.5);
}

.card-title {
  color: #fff;
  font-size: 14px;
  padding-left: 8px;
  height: 20px;
}

.card-content {
  height: calc(100% - 20px);
  color: #fffe;
}
</style>
